import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import bg2 from "../assect/images/bg/02.jpg";

export default function BlogDetail() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [categories, setCategories] = useState([]);
  const [author, setAuthor] = useState("");
  const [recentPosts, setRecentPosts] = useState([]);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        // Add cache-busting parameter
        const cacheBuster = new Date().getTime();
        const postResponse = await fetch(
          `https://cms.svobodajakub.com/wp-json/wp/v2/posts?slug=${slug}&_embed&_cacheBuster=${cacheBuster}`
        );
        const postData = await postResponse.json();
        const postDetails = postData[0];

        setPost({
          id: postDetails.id,
          title: postDetails.title.rendered,
          content: postDetails.content.rendered,
          image:
            postDetails.jetpack_featured_media_url ||
            (postDetails._embedded["wp:featuredmedia"]
              ? postDetails._embedded["wp:featuredmedia"][0].source_url
              : bg2),
          date: new Date(postDetails.date).toLocaleDateString("cs-CZ"),
          categories: postDetails.categories,
        });

        setAuthor(postDetails._embedded?.author?.[0]?.name || "Unknown Author");

        // Fetch all categories associated with the post
        const categoryFetches = postDetails.categories.map((catId) =>
          fetch(
            `https://cms.svobodajakub.com/wp-json/wp/v2/categories/${catId}?_cacheBuster=${cacheBuster}`
          ).then((response) => response.json())
        );

        const categoryData = await Promise.all(categoryFetches);
        setCategories(categoryData.map((cat) => cat.name));
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, [slug]);

  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        // Add cache-busting parameter
        const cacheBuster = new Date().getTime();
        const recentPostsResponse = await fetch(
          `https://cms.svobodajakub.com/wp-json/wp/v2/posts?_embed&per_page=3&orderby=date&order=desc&_cacheBuster=${cacheBuster}`
        );
        const data = await recentPostsResponse.json();

        const formattedRecentPosts = data.map((post) => ({
          id: post.id,
          slug: post.slug,
          title: post.title.rendered,
          date: new Date(post.date).toLocaleDateString("cs-CZ"),
          image:
            post.jetpack_featured_media_url ||
            (post._embedded["wp:featuredmedia"]
              ? post._embedded["wp:featuredmedia"][0].source_url
              : bg2),
        }));

        setRecentPosts(formattedRecentPosts);
      } catch (error) {
        console.error("Error fetching recent posts:", error);
      }
    };

    fetchRecentPosts();
  }, []);

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg2})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                {categories.map((category, index) => (
                  <span className="badge bg-primary me-1" key={index}>
                    {category}
                  </span>
                ))}
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark mt-4">
                  {post?.title || ""}
                </h5>
                <ul className="list-inline text-center mb-0">
                  <li className="list-inline-item mx-4 mt-4">
                    <span className="text-white-50 d-block">Autor</span>
                    <Link to="#" className="text-white title-dark">
                      {author}
                    </Link>
                  </li>
                  <li className="list-inline-item mx-4 mt-4">
                    <span className="text-white-50 d-block">Datum</span>
                    <span className="text-white title-dark">
                      {post?.date || "15. Května 2024"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">DOMŮ</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/blog">Blog</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Blog DETAIL
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-8 col-md-7">
              {/* Blog Details Content */}
              <div className="card border-0 shadow rounded-3 overflow-hidden">
                {/* Blog Image */}
                <img src={post?.image} className="img-fluid" alt="" />
                <div className="card-body">
                  {/* Blog Content */}
                  <div
                    dangerouslySetInnerHTML={{ __html: post?.content }}
                  ></div>
                  {/* Blog Tags */}
                  {categories.map((category, index) => (
                    <Link
                      to="#"
                      className="badge badge-link bg-primary ms-1"
                      key={index}
                    >
                      {category}
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-4 col-md-6 col-12 ">
              <div className="card bg-white p-4 rounded-3 shadow sticky-bar">
                <div>
                  <h6 className="pt-2 pb-2 bg-light rounded-3 text-center">
                    Nedávné Příspěvky
                  </h6>
                  {recentPosts.length === 0 ? (
                    <p className="text-center">Žádné nedávné příspěvky</p>
                  ) : (
                    recentPosts.map((item, index) => (
                      <div
                        className="d-flex align-items-center mt-4"
                        key={index}
                      >
                        <img
                          src={item.image}
                          className="img-fluid avatar avatar-small rounded"
                          alt=""
                        />
                        <div className="flex-1 ms-3">
                          <Link
                            to={`/blog-detail/${item.slug}`} // Use slug in URL
                            className="d-block title text-dark"
                          >
                            {item.title}
                          </Link>
                          <span className="text-muted">{item.date}</span>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
