import React, { useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import bg3 from "../../assect/images/bg/05.jpg";
import PropertyList from "./property-list";
import PropertyFilters from "./search/PropertyFilters";

export default function ListSidebar() {
  const [filters, setFilters] = useState({
    searchTerm: "",
    propertyType: "All",
    category: "All",
  });

  const [submittedFilters, setSubmittedFilters] = useState(filters);

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setSubmittedFilters(filters);
  };

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Nemovitosti k prodeji
                </p>
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Aktuální nabídka
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-4 col-md-6 col-12">
              <PropertyFilters
                filters={filters}
                onFilterChange={handleFilterChange}
                onSubmit={handleFilterSubmit}
              />
            </div>
            <div className="col-lg-8 col-md-6 col-12">
              <PropertyList filters={submittedFilters} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
