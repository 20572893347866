import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import background from "../../assect/images/bg.png";
import bg1 from "../../assect/images/bg/01.jpg";
import bg5 from "../../assect/images/bg/06.jpg";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import BeerSlider from "beerslider";
import "beerslider/dist/BeerSlider.css";
import TinySlider from "tiny-slider-react";
import "../../../node_modules/tiny-slider/dist/tiny-slider.css";

const LoadingSpinner = () => (
  <div className="text-center my-5">
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only"></span>
    </div>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="alert alert-danger text-center my-5" role="alert">
    {message}
  </div>
);

export default function Index() {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sliderInitialized, setSliderInitialized] = useState(false);

  const fetchProperties = async () => {
    const apiURL = `https://cms.svobodajakub.com/wp-json/wp/v2/nemovitost?cacheBuster=${new Date().getTime()}`;
    try {
      const response = await fetch(apiURL);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const propertiesArray = Array.isArray(data) ? data : [data];
      setProperties(propertiesArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Ups. Něco se nezdařilo - prosím aktualizujte stránku.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProperties();

    // Initialize BeerSlider only once
    if (!sliderInitialized) {
      new BeerSlider(document.getElementById("slider"));
      setSliderInitialized(true);
    }
  }, [sliderInitialized]);

  const getStatusClassName = (status) => {
    switch (status) {
      case "K dispozici":
        return "bg-success";
      case "Rezervace":
        return "bg-warning";
      case "Prodáno":
        return "bg-danger";
      default:
        return "bg-secondary";
    }
  };

  const settings = {
    container: ".tiny-slide-three",
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    controlsText: [
      '<i class="mdi mdi-chevron-left "></i>',
      '<i class="mdi mdi-chevron-right"></i>',
    ],
    nav: false,
    speed: 400,
    gutter: 30,
    responsive: {
      1025: {
        items: 3,
      },
      992: {
        items: 2,
      },
      767: {
        items: 2,
      },
      320: {
        items: 1,
      },
    },
  };

  return (
    <>
      <Navbar navClass="defaultscroll sticky" menuClass="navigation-menu" />

      <section
        className="bg-half-120 d-table w-100 pb-0"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "bottom",
        }}
      >
        <div className="bg-overlay bg-primary-gradient-overlay z-n1"></div>
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-12 text-center">
              <div className="title-heading text-center">
                <h4 className="heading fw-bold title-dark mb-5">
                  Jste připraveni se mnou dojet pro Vaše{" "}
                  <span className="text-primary">vysněné bydlení?</span>
                </h4>
                <p className="para-desc text-justify title-dark mx-auto mb-5">
                  Hledání nového domova nebo prodej toho stávajícího nemusí být
                  stresující. Jsem Jakub Svoboda, realiťák, který to má rád na
                  pohodu. Jízda na kole je pro mě jako pro jiné chůze a stejně
                  tak přirozeně vám pomohu najít vaše vysněné bydlení nebo
                  prodat vaši nemovitost. Bez starostí, bez komplikací – pojďme
                  to zvládnout spolu!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="position-relative overflow-hidden shadow rounded-3">
                  <div
                    id="slider"
                    className="beer-slider"
                    data-beer-label="Jakub Svoboda"
                  >
                    <img src={bg5} alt="JakubSvoboda" />
                    <div
                      className="beer-reveal"
                      data-beer-label="Realiťák na kole"
                    >
                      <img src={bg1} alt="RealitakNaKole" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-3">Poslední přidané nemovitosti</h4>
                <p className="para-desc title-dark mb-2 mx-auto">
                  Pravidelně přidávám nové nemovitosti, abyste měli z čeho
                  vybírat. Podívejte se na ty nejnovější z nich.
                </p>
              </div>
            </div>
          </div>

          {loading ? (
            <LoadingSpinner />
          ) : error ? (
            <ErrorMessage message={error} />
          ) : (
            <div className="row">
              <div className="col-12">
                <div className="tiny-slide-three">
                  <TinySlider settings={settings}>
                    {properties.map((item, index) => {
                      const {
                        property_name,
                        propery_photos,
                        property_adress,
                        floor_area,
                        number_bedroom,
                        number_bathroom,
                        property_price,
                        sell_rent,
                        status,
                      } = item.acf || {};

                      // Handle images: Use the first image from the propery_photos array
                      const firstImage =
                        propery_photos && propery_photos.length > 0
                          ? propery_photos[0].url
                          : null;

                      return (
                        <div className="tiny-slide" key={index}>
                          <div className="card property border-0 shadow position-relative overflow-hidden rounded-3">
                            <div className="property-image position-relative overflow-hidden shadow">
                              {firstImage ? (
                                <img
                                  src={firstImage}
                                  className="img-fluid"
                                  alt={property_name}
                                />
                              ) : (
                                <div className="img-placeholder">
                                  Není k dispozici žádný obrázek
                                </div>
                              )}
                            </div>
                            <div className="card-body content p-4 bg-lightblue">
                              <span
                                className={`badge ${getStatusClassName(
                                  status
                                )}`}
                              >
                                {status}
                              </span>{" "}
                              <span className="badge bg-primary">
                                {sell_rent}
                              </span>
                              <br />
                              <Link
                                to={`/detail-nemovitosti/${item.id}`}
                                className="title fs-5 text-dark fw-medium"
                              >
                                {property_name || "Unnamed Property"}
                              </Link>
                              {property_adress && (
                                <p className="text-muted mb-2">
                                  {property_adress.street && (
                                    <>
                                      {property_adress.street}
                                      {property_adress.city && ", "}
                                    </>
                                  )}
                                  {property_adress.city && (
                                    <>
                                      {property_adress.city}
                                      {property_adress.psc && ", "}
                                    </>
                                  )}
                                  {property_adress.psc}
                                </p>
                              )}
                              <ul className="list-unstyled mt-3 py-3 border-top border-bottom d-flex align-items-center justify-content-between">
                                {floor_area && (
                                  <li className="d-flex align-items-center me-3">
                                    <i className="mdi mdi-arrow-expand-all fs-5 me-2 text-primary"></i>
                                    <span className="text-muted">
                                      {floor_area} m²
                                    </span>
                                  </li>
                                )}
                                {number_bedroom && (
                                  <li className="d-flex align-items-center me-3">
                                    <i className="mdi mdi-bed fs-5 me-2 text-primary"></i>
                                    <span className="text-muted">
                                      {number_bedroom}
                                    </span>
                                  </li>
                                )}
                                {number_bathroom && (
                                  <li className="d-flex align-items-center">
                                    <i className="mdi mdi-shower fs-5 me-2 text-primary"></i>
                                    <span className="text-muted">
                                      {number_bathroom}
                                    </span>
                                  </li>
                                )}
                              </ul>
                              <ul className="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                {property_price && (
                                  <li className="list-inline-item mb-0">
                                    <span className="text-muted">Cena</span>
                                    <p className="fw-medium mb-0">
                                      {new Intl.NumberFormat("cs-CZ", {
                                        style: "currency",
                                        currency: "CZK",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }).format(property_price)}
                                    </p>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </TinySlider>
                </div>
              </div>

              <div className="col-12 pt-2">
                <div className="text-center">
                  <Link to="/nabidka" className="mt-3 fs-6 text-primary">
                    Zobrazit všechny nemovitosti v nabídce{" "}
                    <i className="mdi mdi-arrow-right align-middle"></i>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
}
