import React from "react";
import { Link } from "react-router-dom";

import { FiHexagon, FiHome, FiBriefcase, FiKey } from "../assect/icons/vander";

export default function AboutUs() {
  const aboutData = [
    {
      icon: FiHome,
      title: "1",
      desc: "Domluvíme si schůzku, kde se osobně setkáme a já se podívám na vaši nemovitost. Zhodnotíme její stav a probereme vaše představy a potřeby.",
    },
    {
      icon: FiBriefcase,
      title: "2",
      desc: "Po důkladné prohlídce vaši nemovitost profesionálně ocením a nafotím. Připravím atraktivní prezentaci, která zaujme potenciální kupce.",
    },
    {
      icon: FiKey,
      title: "3",
      desc: "Postarám se o celý proces prodeje - od prvního kontaktu se zájemci až po uzavření smlouvy. Budu vás průběžně informovat o každém kroku a zajistím, aby vše proběhlo hladce a bez stresu.",
    },
  ];
  return (
    <>
      <div className="row justify-content-center">
        <div className="col">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-3">JAK BUDEME SPOLUPRACOVAT?</h4>
          </div>
        </div>
      </div>

      <div className="row g-4 mt-0">
        {aboutData.map((item, index) => {
          const Icon = item.icon;
          return (
            <div className="col-md-4" key={index}>
              <div className="position-relative features text-center mx-lg-4 px-md-1">
                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                  <FiHexagon className="hexagon" />
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <Icon className="fea icon-m-md text-primary" />
                  </div>
                </div>

                <div className="mt-4">
                  <Link to="#" className="fw-medium title text-dark fs-5">
                    {item.title}
                  </Link>
                  <p className="text-muted text-justify mt-1 mb-0">
                    {item.desc}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
