import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

const LoadingSpinner = () => (
  <div className="text-center my-5">
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only"></span>
    </div>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="alert alert-danger text-center my-5" role="alert">
    {message}
  </div>
);

const fetchCoordinates = async (query) => {
  const apiKey = "0b2cc46bb21b46a9bd74a3ee6086174a"; // Replace with your OpenCage API key
  const url = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
    query
  )}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.results && data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry;
      return { latitude: lat, longitude: lng };
    } else {
      throw new Error("No results found");
    }
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    return null;
  }
};

export default function PropertyDetails() {
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [open, setIsOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [daysSince, setDaysSince] = useState(0);
  const [coordinates, setCoordinates] = useState(null);
  const [pricePerSqMeter, setPricePerSqMeter] = useState(0);
  const [pdfLink, setPdfLink] = useState(""); // State for PDF link

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Reset error state

      try {
        const response = await fetch(
          `https://cms.svobodajakub.com/wp-json/wp/v2/nemovitost/${id}`
        );
        const data = await response.json();
        setProperty(data);

        // Handle images
        const propertyImages = Array.isArray(data.acf?.propery_photos)
          ? data.acf.propery_photos.map((photo) => photo.url)
          : []; // Ensure propertyImages is always an array
        setImages(propertyImages);

        // Set the PDF link
        if (data.acf?.Analyza_pozemku?.url) {
          setPdfLink(data.acf.Analyza_pozemku.url);
        }

        const calculateDaysSince = (dateString) => {
          const date = new Date(dateString);
          const today = new Date();
          const timeDifference = today - date;
          const daysDifference = Math.floor(
            timeDifference / (1000 * 60 * 60 * 24)
          );
          return daysDifference;
        };

        setDaysSince(calculateDaysSince(data.date));

        if (data.acf?.property_adress?.psc) {
          const addressQuery = `${data.acf.property_adress.street}, ${data.acf.property_adress.psc}`;
          const fetchedCoordinates = await fetchCoordinates(addressQuery);
          setCoordinates(fetchedCoordinates);
        }

        if (data.acf?.property_price && data.acf?.floor_area) {
          const pricePerSqM = data.acf.property_price / data.acf.floor_area;
          setPricePerSqMeter(pricePerSqM.toFixed(2));
        }
      } catch (error) {
        console.error("Problém se získáváním dat:", error);
        setError("Něco se nepovedlo, zkuste později..");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]); // Fetch new data on every change of id

  const getStatusClassName = (status) => {
    switch (status) {
      case "K dispozici":
        return "bg-success";
      case "Rezervace":
        return "bg-warning";
      case "Prodáno":
        return "bg-danger";
      default:
        return "bg-secondary";
    }
  };

  const handleMovePrev = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + images.length - 1) % images.length
    );
  };

  const handleMoveNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const currentImage = images[currentImageIndex];

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!property) return <div>Nebyla nalezena žádná nemovitost</div>;

  return (
    <>
      <Navbar navClass="defaultscroll sticky" menuClass="navigation-menu" />
      <section className="section mt-5 pt-4">
        <div className="container-fluid mt-2">
          <div className="row g-2">
            <div className="col-md-6">
              <Link
                to="#"
                onClick={() => handleImageClick(0)}
                className="lightbox"
                title=""
              >
                {images[0] ? (
                  <img
                    src={images[0]}
                    className="img-fluid rounded-3 shadow"
                    alt=""
                    style={{ maxHeight: "100%", minHeight: "500px" }}
                  />
                ) : (
                  <div className="img-placeholder">
                    Žádný obrázek není k dispozici
                  </div>
                )}
              </Link>
            </div>

            <div className="col-md-6">
              <div className="row g-2">
                {images.slice(1).map((image, index) => (
                  <div className="col-6" key={index}>
                    <Link
                      to="#"
                      onClick={() => handleImageClick(index + 1)}
                      className="lightbox"
                      title=""
                    >
                      <img
                        src={image}
                        className="img-fluid rounded-3 shadow"
                        alt=""
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row g-4">
            <div className="col-lg-8 col-md-7 col-12">
              <div className="section-title">
                <h1 className="title mb-0">{property.acf.property_name}</h1>

                <ul className="list-unstyled mb-0 py-3">
                  {property.acf.floor_area && (
                    <li className="list-inline-item">
                      <span className="d-flex align-items-center me-4">
                        <i className="mdi mdi-home-city-outline fs-4 me-2 text-primary"></i>
                        <span className="text-muted fs-5">
                          {property.acf.property_type}
                        </span>
                      </span>
                    </li>
                  )}

                  {property.acf.floor_area && (
                    <li className="list-inline-item">
                      <span className="d-flex align-items-center me-4">
                        <i className="mdi mdi-arrow-expand-all fs-4 me-2 text-primary"></i>
                        <span className="text-muted fs-5">
                          {property.acf.floor_area} m²
                        </span>
                      </span>
                    </li>
                  )}

                  {property.acf.number_bedroom && (
                    <li className="list-inline-item">
                      <span className="d-flex align-items-center me-4">
                        <i className="mdi mdi-bed fs-4 me-2 text-primary"></i>
                        <span className="text-muted fs-5">
                          {property.acf.number_bedroom} Ložnice
                        </span>
                      </span>
                    </li>
                  )}

                  {property.acf.number_bathroom && (
                    <li className="list-inline-item">
                      <span className="d-flex align-items-center">
                        <i className="mdi mdi-shower fs-4 me-2 text-primary"></i>
                        <span className="text-muted fs-5">
                          {property.acf.number_bathroom} Koupelny
                        </span>
                      </span>
                    </li>
                  )}
                </ul>

                <p className="text-muted text-justify">
                  {property.acf.property_description}
                </p>

                {coordinates && (
                  <div className="card map border-0">
                    <div className="card-body p-0">
                      <iframe
                        src={`https://www.google.com/maps?q=${coordinates.latitude},${coordinates.longitude}&hl=cs;z=14&output=embed`}
                        className="rounded-3"
                        style={{ border: "0", width: "100%", height: "300px" }}
                        title="Google Map"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-4 col-md-5 col-12">
              <div className="rounded-3 shadow bg-white sticky-bar p-4">
                {property.acf.status && (
                  <span
                    className={`badge ${getStatusClassName(
                      property.acf.status
                    )}`}
                  >
                    {property.acf.status}
                  </span>
                )}{" "}
                <span className="badge bg-primary">
                  {property.acf.sell_rent}
                </span>
                <h4>
                  Cena:{" "}
                  {new Intl.NumberFormat("cs-CZ", {
                    style: "currency",
                    currency: "CZK",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(property.acf.property_price)}
                </h4>
                <div className="d-flex align-items-center justify-content-between"></div>
                <div className="mt-3">
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <span className="text-muted">Dní od vystavení</span>
                    <span className="">{daysSince} </span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <span className="text-muted">Dispozice</span>
                    <span>{property.acf.property_layout}</span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <span className="text-muted">Užitná plocha</span>
                    <span>{property.acf.floor_area} m²</span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <span className="text-muted">Cena za m²</span>
                    <span>
                      {new Intl.NumberFormat("cs-CZ", {
                        style: "currency",
                        currency: "CZK",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(pricePerSqMeter)}{" "}
                      / m²
                    </span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <span className="text-muted">Lokalita</span>
                    <span>
                      {property.acf.property_adress.street},{" "}
                      {property.acf.property_adress.city}
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <Link to="/kontakt" className="btn btn-primary w-100 me-2">
                    Mám zájem o nemovitost
                  </Link>
                </div>
                {/* New button to open the PDF link in a new tab */}
                {pdfLink && (
                  <div className="d-flex mt-3">
                    <a
                      href={pdfLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-dark w-100"
                    >
                      Zobrazit Analýzu pozemku
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {open && (
        <Lightbox
          mainSrc={currentImage}
          prevSrc={
            images[(currentImageIndex + images.length - 1) % images.length]
          }
          nextSrc={images[(currentImageIndex + 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </>
  );
}
