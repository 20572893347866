import React, { useEffect, useState } from "react";

const PropertyFilters = ({ filters, onFilterChange, onSubmit }) => {
  const [showLayoutFilter, setShowLayoutFilter] = useState(false);
  const [cities, setCities] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const fetchCitiesAndStatuses = async () => {
    try {
      const cacheBuster = new Date().getTime(); // Generate a unique number to bypass the cache
      const response = await fetch(
        `https://cms.svobodajakub.com/wp-json/wp/v2/nemovitost?_cacheBuster=${cacheBuster}`
      );
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();

      const uniqueCities = [
        ...new Set(data.map((item) => item.acf.property_adress.city)),
      ];
      setCities(uniqueCities);

      const uniqueStatuses = [...new Set(data.map((item) => item.acf.status))];
      setStatuses(uniqueStatuses);
    } catch (error) {
      console.error("Error fetching cities and statuses:", error);
    }
  };

  useEffect(() => {
    fetchCitiesAndStatuses(); // Initial fetch
    const intervalId = setInterval(fetchCitiesAndStatuses, 3600000); // Fetch every hour

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onFilterChange(name, value);

    if (name === "category") {
      setShowLayoutFilter(value === "Byt");
    }
  };

  useEffect(() => {
    setShowLayoutFilter(filters.category === "Byt");
  }, [filters.category]);

  return (
    <div className="card bg-white p-4 rounded-3 shadow sticky-bar">
      <form onSubmit={onSubmit} className="filters mb-4">
        <div className="mb-2">
          <label htmlFor="searchTerm" className="form-label">
            Vyhledejte
          </label>
          <input
            type="text"
            id="searchTerm"
            name="searchTerm"
            value={filters.searchTerm}
            onChange={handleInputChange}
            placeholder="Vyhledejte .. "
            className="form-control"
          />
        </div>
        <div className="mb-2">
          <span className="form-label">
            Prodej / Pronájem
            <br />
          </span>
          <div className="form-check form-check-inline">
            <input
              type="radio"
              id="all"
              name="propertyType"
              value="All"
              checked={filters.propertyType === "All"}
              onChange={handleInputChange}
              className="form-check-input"
            />
            <label htmlFor="all" className="form-check-label">
              Vše
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              type="radio"
              id="prodej"
              name="propertyType"
              value="Prodej"
              checked={filters.propertyType === "Prodej"}
              onChange={handleInputChange}
              className="form-check-input"
            />
            <label htmlFor="prodej" className="form-check-label">
              Prodej
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              type="radio"
              id="pronajem"
              name="propertyType"
              value="Pronájem"
              checked={filters.propertyType === "Pronájem"}
              onChange={handleInputChange}
              className="form-check-input"
            />
            <label htmlFor="pronajem" className="form-check-label">
              Pronájem
            </label>
          </div>
        </div>
        <div className="mb-2">
          <label htmlFor="category" className="form-label">
            Kategorie
          </label>
          <select
            id="category"
            name="category"
            value={filters.category}
            onChange={handleInputChange}
            className="form-select"
          >
            <option value="All">Vše</option>
            <option value="Byt">Byt</option>
            <option value="Rodinný dům">Rodinný dům</option>
            <option value="Obchodní prostor">Obchodní prostor</option>
            <option value="Stavební pozemek">Stavební pozemek</option>
            <option value="Chata">Chata</option>
          </select>
        </div>
        {showLayoutFilter && (
          <div className="mb-2">
            <label htmlFor="propertyLayout" className="form-label">
              Dispozice
            </label>
            <select
              id="propertyLayout"
              name="propertyLayout"
              value={filters.propertyLayout}
              onChange={handleInputChange}
              className="form-select"
            >
              <option value="All">Vše</option>
              <option value="1+1">1+1</option>
              <option value="2+1">2+1</option>
              <option value="3+1">3+1</option>
              <option value="4+1">4+1</option>
              <option value="5+1">5+1</option>
              {/* Add more options as needed */}
            </select>
          </div>
        )}
        <div className="mb-2">
          <label className="form-label">Město</label>
          <select
            id="city"
            name="city"
            value={filters.city}
            onChange={handleInputChange}
            className="form-select"
          >
            <option value="All">Vše</option>
            {cities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-primary mt-3">
            Zobrazit nemovitosti
          </button>
        </div>
      </form>
    </div>
  );
};

export default PropertyFilters;
