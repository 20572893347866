import React from "react";
import { Link } from "react-router-dom";

import { propertyData } from "../data/data";
import { FiHome, FiHeart, FiCamera } from "../assect/icons/vander";

export default function PropertyTwo() {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-3">Nabídka</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              Vybrané nemovitosti, které by Vám neměli uniknout
            </p>
          </div>
        </div>
      </div>

      <div className="row g-4 mt-0">
        {propertyData.slice(0, 6).map((item, index) => {
          return (
            <div className="col-lg-6 col-12" key={index}>
              <div className="card property property-list border-0 shadow position-relative overflow-hidden rounded-3">
                <div className="d-md-flex">
                  <div className="property-image position-relative overflow-hidden shadow flex-md-shrink-0 rounded-3 m-2">
                    <img
                      src={item.image}
                      className="img-fluid h-100 w-100"
                      alt=""
                    />
                  </div>
                  <div className="card-body content p-3">
                    <Link
                      to={`/property-detail/${item.id}`}
                      className="title fs-5 text-dark fw-medium"
                    >
                      {item.title}
                    </Link>

                    <ul className="list-unstyled mt-3 py-3 border-top border-bottom d-flex align-items-center justify-content-between">
                      <li className="d-flex align-items-center me-3">
                        <i className="mdi mdi-arrow-expand-all fs-5 me-2 text-primary"></i>
                        <span className="text-muted">190 m2</span>
                      </li>

                      <li className="d-flex align-items-center me-3">
                        <i className="mdi mdi-bed fs-5 me-2 text-primary"></i>
                        <span className="text-muted">2</span>
                      </li>

                      <li className="d-flex align-items-center">
                        <i className="mdi mdi-shower fs-5 me-2 text-primary"></i>
                        <span className="text-muted">2</span>
                      </li>
                    </ul>
                    <ul className="list-unstyled d-flex justify-content-between mt-2 mb-0">
                      <li className="list-inline-item mb-0">
                        <span className="text-muted">Cena</span>
                        <p className="fw-medium mb-0">1 276 000 Kč</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="col-12 mt-4 pt-2">
          <div className="text-center">
            <Link to="/list-sidebar" className="mt-3 fs-6 text-primary">
              Všechny nemovitosti v nabídce{" "}
              <i className="mdi mdi-arrow-right align-middle"></i>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
