import React, { useEffect, useState } from "react";
import PropertyCard from "./PropertyCard";
import LoadingSpinner from "./search/LoadingSpinner";
import ErrorMessage from "./search/ErrorMessage";
import NoResultsMessage from "./search/NoResultsMessage";

const PropertyList = ({ filters }) => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [displayCount, setDisplayCount] = useState(5);

  const apiURL = "https://cms.svobodajakub.com/wp-json/wp/v2/nemovitost";

  const fetchData = async (
    filterParams = {},
    page = 1,
    accumulatedData = []
  ) => {
    setLoading(true);
    setError(null);

    const cacheBuster = new Date().getTime();
    const params = new URLSearchParams(filterParams);
    params.append("page", page);
    params.append("per_page", 100);
    params.append("_cacheBuster", cacheBuster);

    const requestURL = `${apiURL}?${params.toString()}`;

    try {
      const response = await fetch(requestURL, { cache: "no-store" });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const allData = accumulatedData.concat(data);

      const totalPages = response.headers.get("X-WP-TotalPages");

      if (page < totalPages) {
        fetchData(filterParams, page + 1, allData);
      } else {
        const sortedData = allData.sort((a, b) => {
          const statusOrder = { "K dispozici": 1, Rezervace: 2 };
          return (
            (statusOrder[a.acf.status] || 3) - (statusOrder[b.acf.status] || 3)
          );
        });

        setProperties(Array.isArray(sortedData) ? sortedData : []);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(
        error.message === "Failed to fetch"
          ? "Network error or CORS issue. Please check your connection or try again later."
          : "An unexpected error occurred. Please try again later."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const filterParams = {};
    if (filters.searchTerm) filterParams.property_name = filters.searchTerm;
    if (filters.propertyType && filters.propertyType !== "All")
      filterParams.sell_rent = filters.propertyType;
    if (filters.category && filters.category !== "All")
      filterParams.property_type = filters.category;
    if (
      filters.category === "Byt" &&
      filters.propertyLayout &&
      filters.propertyLayout !== "All"
    )
      filterParams.property_layout = filters.propertyLayout;
    if (filters.city && filters.city !== "All")
      filterParams.city = filters.city;
    if (filters.status && filters.status !== "All")
      filterParams.status = filters.status;

    fetchData(filterParams);
  }, [filters]);

  const handleLoadMore = () => {
    setDisplayCount((prevCount) => prevCount + 5);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (properties.length === 0) return <NoResultsMessage />;

  return (
    <div>
      <div className="row g-4">
        {properties.slice(0, displayCount).map((item) => (
          <PropertyCard key={item.id} item={item} />
        ))}
      </div>
      {displayCount < properties.length && (
        <div className="text-center">
          <button onClick={handleLoadMore} className="btn btn-primary mt-4">
            Načíst další nemovitosti
          </button>
        </div>
      )}
    </div>
  );
};

export default PropertyList;
