import React from "react";

import bg2 from "../assect/images/bg/05.jpg";

import Navbar from "../components/navbar";
import GetInTuch from "../components/getInTuch";
import AboutUsTwo from "../components/aboutTwo";
import PropertyTwo from "../components/propertyTwo";
import BuyTab from "../components/buyTab";
import Footer from "../components/footer";

export default function Buy() {
  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-light"
      />
      <section
        className="bg-half-120 d-table w-100"
        style={{ backgroundImage: `url(${bg2})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Nemovitosti v nabídce
                </p>
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  VYBERTE DOKONALOU NEMOVITOST
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="features-absolute subscribe-search">
                <div className="row justify-content-center">
                  <div className="col-lg-7 col-md-9">
                    <div className="text-center subscribe-form">
                      <form style={{ maxWidth: "800px" }}>
                        <div className="mb-0">
                          <input
                            type="text"
                            id="help"
                            name="name"
                            className="border shadow rounded-3 bg-white"
                            required=""
                            placeholder="Město, adresa, PSČ.."
                          />
                          <button
                            type="submit"
                            className="btn btn-primary rounded-3"
                          >
                            Vyhledat
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <PropertyTwo />
        </div>

        <div className="container mt-100 mt-60">
          <AboutUsTwo />
        </div>

        <div className="container mt-100 mt-60">
          <BuyTab />
        </div>

        <div className="container mt-100 mt-60">
          <GetInTuch />
        </div>
      </section>
      <Footer />
    </>
  );
}
