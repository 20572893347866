import property1 from "../assect/images/property/1.jpg";

import client1 from "../assect/images/client/01.jpg";
import client2 from "../assect/images/client/02.jpg";

export const propertyData = [
  {
    id: 1,
    image: property1,
    title: "Václavské nám. 840/5, 110 00 Můstek, Praha",
  },
];

export const clientData = [
  {
    image: client1,
    name: "KAŽDÁ ŽENA PLANETY",
    title: "Co to viděla",
    desc: '" Kuba má fakt velký péro."',
  },
  {
    image: client2,
    name: "PLACEHOLDER RECENZE",
    title: "Lorem ipsum",
    desc: '" Kuba má fakt velký péro."',
  },
];

export const blogData = [
  {
    id: 1,
    image: property1,
    tag: "Trh nemovitostí",
    title: "Kuba jezdí dobře na kole, ještě lépe zajede pro Vaše nemovitosti",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec ipsum massa, ullamcorper in, auctor et, scelerisque sed, est. Curabitur sagittis hendrerit ante. Fusce suscipit libero eget elit. Morbi leo mi, nonummy eget tristique non, rhoncus non leo. Vestibulum fermentum tortor id mi. Nullam rhoncus aliquam metus. Duis sapien nunc, commodo et, interdum suscipit, sollicitudin et, dolor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin in tellus sit amet ni.",
    date: "",
  },
];
