import React, { useEffect } from "react"; // Ujistěte se, že useEffect je importován
import bg3 from "../assect/images/bg/03.jpg";
import Navbar from "../components/navbar";
import AboutUs from "../components/about";
import Footer from "../components/footer";

const Sell = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-light"
      />

      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2" />
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <p className="text-white-50 para-desc mx-auto mb-0">
                  POMŮŽU VÁM PRODAT NEMOVITOST
                </p>
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Prodávejte snadněji, za co nejlepší cenu.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <AboutUs />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Sell;
