import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import bg2 from "../assect/images/bg/02.jpg"; // Default image
import Footer from "../components/footer";

const LoadingSpinner = () => (
  <div className="text-center my-5">
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">...</span>
    </div>
  </div>
);

export default function Blogs() {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const postsPerPage = 6;
  const apiURL = "https://cms.svobodajakub.com/wp-json/wp/v2";

  // Function to fetch data with cache-busting
  const fetchData = async (page = 1) => {
    setLoading(true);
    setError(null);

    // Add cache-busting parameter
    const cacheBuster = new Date().getTime();
    const postsRequestURL = `${apiURL}/posts?_embed&page=${page}&per_page=${postsPerPage}&_cacheBuster=${cacheBuster}`;

    try {
      const response = await fetch(postsRequestURL, { cache: "no-store" });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const formattedData = data.map((post) => ({
        id: post.id,
        title: post.title.rendered,
        slug: post.slug,
        desc: post.excerpt.rendered,
        image:
          post.jetpack_featured_media_url ||
          (post._embedded && post._embedded["wp:featuredmedia"]
            ? post._embedded["wp:featuredmedia"][0].source_url
            : bg2),
        category: post.categories[0],
      }));

      setPosts(formattedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setError(
        "An error occurred while fetching posts. Please try again later."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);

    // Fetch categories
    const fetchCategories = async () => {
      const cacheBuster = new Date().getTime();
      const categoriesRequestURL = `${apiURL}/categories?_cacheBuster=${cacheBuster}`;

      try {
        const response = await fetch(categoriesRequestURL);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const categoryMap = data.reduce((acc, category) => {
          acc[category.id] = category.name;
          return acc;
        }, {});
        setCategories(categoryMap);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [currentPage]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(posts.length / postsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <LoadingSpinner />;
  if (error)
    return (
      <div className="text-center my-5">
        <p className="text-danger">{error}</p>
      </div>
    );

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg2})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <p className="text-white-50 para-desc mx-auto mb-0">Novinky</p>
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  BLOG a Zprávy ze světa nemovitostí
                </h5>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">Domů</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Blog
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row g-4">
            {currentPosts.map((item, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="card blog blog-primary shadow rounded-3 overflow-hidden border-0">
                  <div className="card-img blog-image position-relative overflow-hidden rounded-0">
                    <div className="position-relative overflow-hidden">
                      <img src={item.image} className="img-fluid" alt="" />
                      <div className="card-overlay"></div>
                    </div>

                    <div className="blog-tag p-3">
                      <Link to="" className="badge bg-primary">
                        {categories[item.category] || "General"}
                      </Link>
                    </div>
                  </div>

                  <div className="card-body content p-0">
                    <div className="p-4">
                      <Link
                        to={`/blog-detail/${item.slug}`}
                        className="title fw-medium fs-5 text-dark"
                      >
                        {item.title}
                      </Link>
                      <div
                        className="text-muted mt-2"
                        dangerouslySetInnerHTML={{ __html: item.desc }}
                      ></div>

                      <Link
                        to={`/blog-detail/${item.slug}`}
                        className="text-dark read-more"
                      >
                        Číst celý článek{" "}
                        <i className="mdi mdi-chevron-right align-middle"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="row">
            <div className="col-12 mt-4 pt-2">
              <ul className="pagination justify-content-center mb-0">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <Link
                    className="page-link"
                    to="#"
                    aria-label="Previous"
                    onClick={() => paginate(currentPage - 1)}
                  >
                    <span aria-hidden="true">
                      <i className="mdi mdi-chevron-left fs-6"></i>
                    </span>
                  </Link>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    className={`page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                    key={i}
                  >
                    <Link
                      className="page-link"
                      to="#"
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </Link>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <Link
                    className="page-link"
                    to="#"
                    aria-label="Next"
                    onClick={() => paginate(currentPage + 1)}
                  >
                    <span aria-hidden="true">
                      <i className="mdi mdi-chevron-right fs-6"></i>
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
