import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/navbar";
import bg4 from "../assect/images/bg/04.jpg";

import { FiPhone, FiHexagon, FiMapPin, FiMail } from "../assect/icons/vander";
import Footer from "../components/footer";
import { contactInfo } from "../components/contactInfo";

export default function ContactUs() {
  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-light"
      />
      <section
        className="bg-half-120 d-table w-100"
        style={{ backgroundImage: `url(${bg4})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Pojďme spolupracovat!
                </p>
                <h4 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Kontakt
                </h4>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">Domů</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  kontakt
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>

      <section className="section pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="p-4 rounded-3 shadow">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Vaše jméno <span className="text-danger">*</span>
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Jméno :"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Váš Email <span className="text-danger">*</span>
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Email :"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">Předmět</label>
                        <input
                          name="subject"
                          id="subject"
                          className="form-control"
                          placeholder="Předmět :"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Váš dotaz <span className="text-danger">*</span>
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          rows="4"
                          className="form-control"
                          placeholder="Zpráva :"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          type="submit"
                          id="submit"
                          name="send"
                          className="btn btn-primary"
                        >
                          Odeslat
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row g-4">
            <div className="col-md-4">
              <div className="position-relative features text-center mx-lg-4 px-md-1">
                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                  <FiHexagon className="hexagon" />
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <FiPhone className="fea icon-m-md text-primary" />
                  </div>
                </div>

                <div className="mt-4">
                  <h4 className="mb-3">Můj telefon</h4>
                  <p className="text-muted">
                    Zavolat je to nejlepší co můžete udělat, vše vyřešíme
                    individálně.
                  </p>
                  <Link
                    to={`tel:${contactInfo.phone}`}
                    className="text-primary"
                  >
                    {contactInfo.phone}
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="position-relative features text-center mx-lg-4 px-md-1">
                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                  <FiHexagon className="hexagon" />
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <FiMail className="fea icon-m-md text-primary" />
                  </div>
                </div>

                <div className="mt-4">
                  <h4 className="mb-3">Můj email</h4>
                  <p className="text-muted">
                    Pomocí formuláře napište jakýkoliv dotaz Vás napadne.
                  </p>
                  <Link
                    to={`mailto:${contactInfo.email}`}
                    className="text-primary"
                  >
                    {contactInfo.email}
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="position-relative features text-center mx-lg-4 px-md-1">
                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                  <FiHexagon className="hexagon" />
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <FiMapPin className="fea icon-m-md text-primary" />
                  </div>
                </div>

                <div className="mt-4">
                  <h4 className="mb-3">Kde mě najdete?</h4>
                  <p className="text-muted">
                    {contactInfo.address.line1}, <br />
                    {contactInfo.address.line2} {contactInfo.address.line3}
                  </p>
                  <a
                    href="https://maps.app.goo.gl/rSj1AH6HUwmyVpi5A"
                    target="_blank"
                  >
                    Zobrazit na mapách Google
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-100 mt-60">
          <div className="row">
            <div className="col-12 p-0">
              <div className="card map border-0">
                <div className="card-body p-0">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2553.1148405474974!2d15.815237200000004!3d50.2150744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470c2b23be96092f%3A0x3332ce435d02ea5a!2zQ2hlbMSNaWNrw6lobyA5NjcvMTAsIDUwMCAwMiBIcmFkZWMgS3LDoWxvdsOpIDItUHJhxb5za8OpIFDFmWVkbcSbc3TDrQ!5e0!3m2!1scs!2scz!4v1717615317640!5m2!1scs!2scz"
                    style={{ border: "0" }}
                    title="Home"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
